import Vue from 'vue';
import VCalendar from 'v-calendar';
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

// Use v-calendar, v-date-picker & v-popover components
Vue.component('vc-date-picker', DatePicker);

Vue.use(VCalendar, {
    componentPrefix: 'vc'
});

export default VCalendar
