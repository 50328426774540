<template>
  <v-app
    style="background: linear-gradient(to bottom, #C4E3CE, #F7FBF8 300px);"
  >
    <AvisosSnack />
    <LoginDialog />
    <Header />
    <v-main>
      <v-container fluid>
        <v-row justify="center">
          <v-col :cols="windowWidth <= 1440 ? 12 : 10">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import LoginDialog from "@/components/login/LoginDialog";
import AvisosSnack from "@/components/AvisosSnack";

export default {
  name: "App",

  components: {
    Header,
    Footer,
    LoginDialog,
    AvisosSnack
  },

  data() {
    return {
      windowWidth: window.innerWidth
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    }
  }
};
</script>

<style lang="css">
@import "~nprogress/nprogress.css";

.ingiravel .v-icon {
  transition: none !important;
  transform: none !important;
}

#nprogress .bar {
  background: #f28c00;
}

#nprogress .peg {
  box-shadow: 0 0 10px #f28c00, 0 0 5px #f28c00;
}

.titulo {
  color: #3d4441;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4);
}

.tituloForm {
  color: #89c79e;
}

.logo {
  color: #3d4441;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4)
}

#logo-description {
  color: #3d4441;
  text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.4)
}
</style>
