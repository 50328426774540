import Vue from 'vue'
import { load } from "recaptcha-v3";

export default {
    async verificarRecaptcha(acao) {

        if (process.env.NODE_ENV === "development") {
            console.log(process.env)
            return true;
        }

        const recaptcha = await load("6LdUJdEZAAAAADTTrvtsI7W78YLEcjOq70Vyxh0r", { autoHideBadge: true });
        const token = await recaptcha.execute(acao);
        return Vue.axios.get("recaptcha/public/verificar", { params: { recaptchaToken: token } })
            .then((response) => {
                let data = response.data;
                return data.success && data.score > 0.5
            }).catch(() => {});
    }
}