import Vue from "vue";
import luxon from "@/core/luxon";

export default {
    enviarEmailPrimeiroAcesso(dados) {
        return Vue.axios.post("usuario/public/primeiroAcesso", {
            dadosPessoais: {
                nome: dados.nome,
                cpf: dados.cpf,
                dataNascimento: this.dataConverter(dados.dataNascimento),
                mae: dados.mae,
                email: dados.email
            },
            senha: dados.senha
        });
    },

    confirmarPrimeiroAcesso(dados) {
        return Vue.axios.post("usuario/public/primeiroAcesso/confirmar", dados);
    },

    trocarSenha(senhaAtual, novaSenha) {
        return Vue.axios.post("usuario/trocarSenha", {
            senhaAtual: senhaAtual,
            novaSenha: novaSenha
        });
    },

    recuperarFiltro(paramsFiltro, pagina, numElementosPagina, ordenacao) {
        return Vue.axios.post("usuario/filtro", {
            params: {
                nome: paramsFiltro.nome,
                cpf: paramsFiltro.cpf,
                email: paramsFiltro.email,
                habilitado: paramsFiltro.habilitado,
                perfil: paramsFiltro.perfil
            },
            pagina,
            numElementosPagina,
            ordenacao
        });
    },

    recuperarFiltroPorProcessoOuOpcao(paramsFiltro, pagina, numElementosPagina, ordenacao) {
        return Vue.axios.post("usuario/filtro/escopo", {
            params: {
                nome: paramsFiltro.nome,
                cpf: paramsFiltro.cpf,
                email: paramsFiltro.email,
                habilitado: paramsFiltro.habilitado,
                nomeProcesso: paramsFiltro.nomeProcesso,
                nomeOpcao: paramsFiltro.nomeOpcao
            },
            pagina,
            numElementosPagina,
            ordenacao
        });
    },

    criarUsuario(dados) {
        return Vue.axios.post("usuario", {
            dadosPessoais: {
                nome: dados.nome,
                cpf: dados.cpf,
                dataNascimento: this.dataConverter(dados.dataNascimento),
                mae: dados.mae,
                email: dados.email
            },
            senha: dados.senha,
            perfil: dados.perfil,
            habilitado: dados.habilitado
        });
    },

    atualizarUsuario(dados) {
        return Vue.axios.put("usuario/" + dados.oid, {
            dadosPessoais: {
                nome: dados.nome,
                cpf: dados.cpf,
                dataNascimento: this.dataConverter(dados.dataNascimento),
                mae: dados.mae,
                email: dados.email
            },
            senha: dados.senha,
            perfil: dados.perfil,
            habilitado: dados.habilitado
        });
    },

    dataConverter(data){
        return luxon.localDateToDateTime(data.replace(/\//g, '-')).toFormat(
            "yyyy-LL-dd TT"
        );
    }
};
