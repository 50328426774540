import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import store from "@/core/vuex";
import { Perfil } from "@/commons/constants";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "PaginaInicial",
    component: () =>
      import("@/components/pages/PaginaInicial/PaginaInicial.vue"),
    meta: {
      title: "Página Inicial",
      requiresAuth: false
    }
  },
  {
    path: "/recuperarSenha",
    name: "RecuperarSenha",
    component: () => import("@/components/pages/RecuperarSenha.vue"),
    meta: {
      title: "Recuperar Senha",
      requiresAuth: false
    }
  },
  {
    path: "/primeiroAcesso",
    name: "PrimeiroAcesso",
    component: () => import("@/components/pages/PrimeiroAcesso.vue"),
    meta: {
      title: "Primeiro Acesso",
      requiresAuth: false
    }
  },
  {
    path: "/erro",
    name: "Erro",
    component: () => import("@/components/pages/Erro.vue"),
    meta: {
      title: "Erro!",
      requiresAuth: false
    }
  },
  {
    path: "/naoAutorizado",
    name: "NaoAutorizado",
    component: () => import("@/components/pages/NaoAutorizado.vue"),
    meta: {
      title: "Não Autorizado",
      requiresAuth: false
    }
  },
  {
    path: "/naoEncontrado",
    name: "404",
    component: () => import("@/components/pages/NaoEncontrado.vue"),
    meta: {
      title: "Não Encontrado",
      requiresAuth: false
    }
  },
  {
    path: "*",
    redirect: "/naoEncontrado"
  },
  {
    path: "/trocarSenha",
    name: "TrocarSenha",
    component: () => import("@/components/pages/TrocarSenha.vue"),
    meta: {
      title: "Trocar Senha",
      requiresAuth: true
    }
  },
  {
    path: "/divulgacao/processo/:oid",
    name: "ProcessoDivulgacao",
    component: () => import("@/components/pages/ProcessoDivulgacao.vue"),
    meta: {
      title: "Divulgação do Processo",
      requiresAuth: false
    }
  },
  {
    path: "/inscricao/processo/:oid",
    name: "Inscricao",
    component: () => import("@/components/pages/Inscricao/Inscricao.vue"),
    meta: {
      title: "Inscrição",
      requiresAuth: true
    }
  },
  {
    path: "/recurso/processo/:oid",
    name: "Recurso",
    component: () => import("@/components/pages/Inscricao/Recurso.vue"),
    meta: {
      title: "Recurso",
      requiresAuth: true
    }
  },
  {
    path: "/documentacaoPendente/processo/:oid",
    name: "DocumentacaoPendente",
    component: () => import("@/components/pages/Inscricao/DocumentacaoPendente.vue"),
    meta: {
      title: "Documentacao Pendente",
      requiresAuth: true
    }
  },
  {
    path: "/admin/estatisticas",
    name: "Estatísticas",
    component: () => import("@/components/pages/admin/processos/Estatisticas.vue"),
    meta: {
      title: "Estatísticas",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/admin/materialAplicacao",
    name: "Material de Aplicação",
    component: () => import("@/components/pages/admin/materialAplicacao/MaterialAplicacao"),
    meta: {
      title: "Material de Aplicação",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/admin/envioDocumentos",
    name: "Envio de Documentos",
    component: () => import("@/components/pages/admin/envioDocumentos/EnvioDocumentos.vue"),
    meta: {
      title: "Envio de Documentos",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/admin/divulgacao",
    name: "Divulgação",
    component: () => import("@/components/pages/admin/consultas/Divulgacao"),
    meta: {
      title: "Divulgação",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },

  {
    path: "/admin/relatorios",
    name: "Relatórios",
    component: () => import("@/components/pages/admin/relatorios/Relatorios"),
    meta: {
      title: "Relatórios",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/components/pages/admin/dashboard/Dashboard.vue"),
    meta: {
      title: "Dashboard",
      requiresAuth: true,
      roles: [Perfil.Administrador, Perfil.Avaliador]
    }
  },
  {
    path: "/admin/consulta/usuarios",
    name: "Usuarios",
    component: () => import("@/components/pages/admin/consultas/Usuarios.vue"),
    meta: {
      title: "Consulta - Usuários",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/admin/consulta/dadosPessoais",
    name: "DadosPessoais",
    component: () =>
      import("@/components/pages/admin/consultas/DadosPessoais.vue"),
    meta: {
      title: "Consulta - Dados Pessoais",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/admin/consulta/cep",
    name: "CEP",
    component: () =>
        import("@/components/pages/admin/consultas/Cep.vue"),
    meta: {
      title: "Consulta - CEP",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/admin/consulta/instituicaoEnsino",
    name: "Instituição de Ensino Superior",
    component: () =>
        import("@/components/pages/admin/consultas/InstituicaoEnsinoSuperior.vue"),
    meta: {
      title: "Consulta - Instituição de Ensino Superior",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/admin/consulta/inscricao/:processoOid?",
    name: "InscricaoFiltro",
    component: () => import("@/components/pages/admin/consultas/Inscricao.vue"),
    meta: {
      title: "Consulta - Inscrição",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/admin/processos",
    name: "Processos",
    component: () => import("@/components/pages/admin/processos/Processo.vue"),
    meta: {
      title: "Processos",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/admin/processos/:oid",
    name: "ProcessoSelecionado",
    component: () =>
      import("@/components/pages/admin/processos/ProcessoSelecionado.vue"),
    meta: {
      title: "Processo",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },

  {
    path: "/avaliadores",
    name: "GerenciamentoAvaliadores",
    component: () =>
      import("@/components/pages/admin/avaliacao/Avaliadores.vue"),
    meta: {
      title: "Gerenciamento de Avaliadores",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/lancamento",
    name: "FiltroLNF",
    component: () =>
      import("@/components/pages/admin/lancamento/FiltroLancamento.vue"),
    meta: {
      title: "Filtro de Lançamento de Nota",
      requiresAuth: true,
      roles: [Perfil.Administrador, Perfil.Avaliador]
    }
  },
  {
    path: "/documentacao",
    name: "FiltroDocumentos",
    component: () =>
        import("@/components/pages/admin/documentos/FiltroDocumento.vue"),
    meta: {
      title: "Visualizar Documentos",
      requiresAuth: true,
      roles: [Perfil.Administrador, Perfil.Avaliador]
    }
  },
  {
    path: "/admin/consulta/notaEnem/:processoOid?",
    name: "NotasEnemFiltro",
    component: () => import("@/components/pages/admin/consultas/NotaEnem.vue"),
    meta: {
      title: "Notas Enem",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/admin/consulta/classificacao/:processoOid?",
    name: "ClassificacaoFiltro",
    component: () =>
      import("@/components/pages/admin/consultas/Classificacao.vue"),
    meta: {
      title: "Classificação",
      requiresAuth: true,
      roles: [Perfil.Administrador]
    }
  },
  {
    path: "/cartaoConfirmacao/processo/:oid",
    name: "CartaoConfirmacao",
    component: () => import("@/components/pages/Inscricao/CartaoConfirmacao.vue"),
    meta: {
      title: "Cartão de Confirmação de Inscrição",
      requiresAuth: true
    }
  },

];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  document.title = "SGA UFRJ - " + to.meta.title || "SGA UFRJ";

  NProgress.start();

  if (!store.state.autenticacao.usuarioLogado) {
    await store.dispatch("autenticacao/refresh");
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.autenticacao.usuarioLogado) {
      next(false);
      NProgress.done();
      await store.dispatch("autenticacao/exibirLoginEIrPara", {
        exibir: true,
        irPara: to.fullPath
      });
    } else if (
      to.meta.roles &&
      !to.meta.roles.some(record =>
        store.state.autenticacao.usuarioLogado.permissoes.includes(record)
      )
    ) {
      next("/naoAutorizado");
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done()
})

export default router
