import Vue from 'vue'
import Vuex from 'vuex'

import { autenticacao } from '@/stores/autenticacao.module';
import { avisos } from '@/stores/avisos.module';
import {processoSelecionado} from "@/stores/processoSelecionado.module";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: { autenticacao, avisos, processoSelecionado },
});
