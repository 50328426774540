import autenticacaoService from "@/services/autenticacao.service";
import router from "@/core/router";
import {Perfil} from "@/commons/constants";

const state = {
    loginDialogAberto: false,
    loginDialogIrPara: undefined,
    usuarioLogado: null,
    apiBuildNumber: null,
    refreshTokenTimeout: null
};

const mutations = {
    setLoginDialogAberto(state, exibir) {
        state.loginDialogAberto = exibir;
    },

    setLoginDialogIrPara(state, irPara) {
        state.loginDialogIrPara = irPara;
    },

    setApiBuildNumber(state, buildNumber) {
        state.apiBuildNumber = buildNumber;
    },

    setUsuarioLogado(state, data) {
        if (data === null) {
            state.usuarioLogado = null;
        } else {
            state.usuarioLogado = {
                nome: data.user.name,
                permissoes: data.user.authorities.map(a => a.authority),
                accessToken: data.access_token,
                validade: new Date(Date.now() + (data.expires_in * 1000)),
            }
        }
    },

    startRefreshTokenTimeout(state, callback) {
        const expires = state.usuarioLogado.validade.getTime();
        const timeout = expires - Date.now() - (300 * 1000);
        state.refreshTokenTimeout = setTimeout(callback, timeout);
    },

    stopRefreshTokenTimeout(state) {
        clearTimeout(state.refreshTokenTimeout);
    }
}

const actions = {
    exibirLogin({commit}) {
        commit('setLoginDialogAberto', true);
        commit("setUsuarioLogado", null);
        commit("stopRefreshTokenTimeout");
    },

    exibirLoginEIrPara({commit}, {exibir, irPara}) {
        commit('setLoginDialogAberto', exibir);
        commit('setLoginDialogIrPara', irPara);
        if (exibir) {
            commit("setUsuarioLogado", null);
            commit("stopRefreshTokenTimeout");
        }
    },

    login({commit, dispatch}, credenciais) {
        return new Promise((resolve, reject) => {
            autenticacaoService.login(credenciais)
                .then(response => {
                    let data = response.data;
                    let buildNumber = response.headers["build"];
                    commit('setApiBuildNumber', buildNumber);
                    commit('setUsuarioLogado', data);
                    commit('startRefreshTokenTimeout', () => dispatch('refresh'))
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                });
        })
    },

    async refresh({commit, dispatch}) {
        await autenticacaoService.refresh()
            .then(response => {
                let data = response.data;
                let buildNumber = response.headers["build"];
                commit('setApiBuildNumber', buildNumber);
                commit('setUsuarioLogado', data);
                commit('startRefreshTokenTimeout', () => dispatch('refresh'))
            })
            .catch(() => {
                commit("setUsuarioLogado", null);
                commit("stopRefreshTokenTimeout");
            });
    },

    logout({commit}) {
        autenticacaoService.logout().then(() => {
            commit("setUsuarioLogado", null);
            commit("stopRefreshTokenTimeout");
            router.push("/").catch(() => {
            });
        })
    },
}

const getters = {

    isAdministrador() {
        if (!state.usuarioLogado) {
            return false;
        }
        return state.usuarioLogado.permissoes.includes(Perfil.Administrador);
    },
    isAvaliador() {
        if (!state.usuarioLogado) {
            return false;
        }
        return state.usuarioLogado.permissoes.includes(Perfil.Avaliador);
    }
}

export const autenticacao = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
