import luxon from "@/core/luxon";


export default {
    camposIguaisRule(campoValor, campoBase, mensagem) {
        return (campoValor && campoValor === campoBase) || mensagem;
    },

    campoObrigatorioRule(v) {
        let ok;
        if (typeof v === "boolean") {
            ok = true
        } else if (Array.isArray(v)) {
            ok = v.length > 0;
        } else if (typeof v === "number") {
            ok = true;
        }
        else {
            ok = !!v;
        }
        return ok || "Campo obrigatório!";
    },

    linkObrigatorioRule(v, instrumentoMusicalPiano) {
        let ok;
        if (instrumentoMusicalPiano) {
            ok = true;
        } else if (v === null || v === '' ) {
            ok = false;
        } else {
            ok = !!v;
        }

        return ok || "Campo obrigatório!";
    },

    campoAvaliacaoObrigatoriaRule(v, situacao) {
        let ok = true;
        if (situacao === 'NAP') {
            if(!v) {
                ok = false;
            } else {
                ok = !!v.replace(/\s*/g,"");
            }
        }
        return ok || "Campo obrigatório!";
    },

    campoObrigatorioCheckBoxRule(v) {
        return v || "Campo obrigatório!";
    },

    tamanhoCPFRule(v) {
        return (v && v.length === 11) || "CPF deve conter 11 caracteres!";
    },

    tamanhoSenhaRule(v) {
        return (v && v.length >= 8) || "Senha deve conter no mínimo 8 caracteres!";
    },

    tamanhoCEPRule(v) {
        return (v && v.length === 8) || "CEP deve conter 8 caracteres!";
    },

    tamanhoRule(tamanho) {
        return valor => {
            return (
                (valor && valor.toString().length === tamanho) ||
                "Esse campo deve conter " + tamanho + " caracteres!"
            );
        };
    },

    tamanhoMaximoRule(tamanho) {
        return valor => {
            return (
                (!valor) ||
                (valor && valor.length <= tamanho) ||
                "Esse campo deve ter até " + tamanho + " caracteres!"
            );
        }
    },

    formatoNomeRule(v) {
        return (
            (v &&
                /^[A-ZÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-ZÀ-Ÿ][A-zÀ-ÿ']/.test(v)) ||
            "Nome inválido!"
        );
    },

    formatoEmailRule(v) {
        return /.+@.+\..+/.test(v) || "Formato de email inválido!";
    },

    formatoDataRule(v) {
        return (
            /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(
                v
            ) || "Data inválida!"
        );
    },

    prefixoRule(prefixos) {
        return valor => {
            return (valor && prefixos.includes(valor.slice(0,2)) ||
                "O Número de Inscrição no ENEM é de um ano inválido para este processo");
        };
    },

    validarNumeroEnemBaseadoNoAnoOrigem(anoOrigem) {

        return valor => {
            return (
                (valor && anoOrigem && parseInt(valor.slice(0, 2)) <= (parseInt(anoOrigem.slice(2, 4)) - 1)) ||
                "O Enem utilizado deve ser referente ao ano de ingresso na universidade de origem."
            );
        };
    },

    validarMatriculaBaseadoNoNumeroEnem(numeroEnem) {
        return matricula => {
            return (
                matricula.slice(1, 3) - 1 === parseInt(numeroEnem.slice(0, 2)) || matricula.length < 9 || numeroEnem.length < 12 ||
                "O Enem utilizado deve ser referente ao ano anterior ao de sua matrícula. Exemplo: Matrícula de 2012 deve utilizar o Enem de 2011."
            );
        };
    },

    dataAnterior(dataAnterior, dataPosterior) {
        let data1 = luxon.dataConverter(dataAnterior);
        let data2 = luxon.dataConverter(dataPosterior);

        return (dataAnterior === null || dataPosterior === null) ||
            (   ( data1 < data2 ) ||
                "A data deve ser anterior a de fim!"
            );
    },

    dataPosterior(dataAnterior, dataPosterior) {
        let data1 = luxon.dataConverter(dataAnterior);
        let data2 = luxon.dataConverter(dataPosterior);

        return (dataAnterior === null || dataPosterior === null) ||
            ((data2 > data1) ||
                "A data deve ser posterior a de inicio!"
            );
    },

    linkVideoTHEValido(v) {
        let ok;

        if (v === null || v === '') {
            ok = true;
        } else {
            ok = v.startsWith('https://the.musica.ufrj.br/');
        }

        return ok || "O link preenchido não é de Dominio da UFRJ."
    },

    formatoEmail(v) {
        return ((v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || "Formato de email inválido! Verifique se existe algum espaço em branco no final")
    },

};
