import processoService from "@/services/processo.service";
import _ from "lodash"

const state = {
    processoSelecionado: {}
};

const mutations = {
    setProcessoSelecionado(state, processo) {
        state.processoSelecionado = processo;
    },
}

const actions = {
    atualizarProcessoSelecionado({commit}, processoOid) {
        return new Promise((resolve, reject) => {
            processoService.recuperarProcesso(processoOid)
                .then(response => {
                    commit('setProcessoSelecionado', response.data);
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                });
        })
    }
}

const getters = {
    possuiProcessoSelecionado() {
        return !_.isEmpty(state.processoSelecionado);
    }
}

export const processoSelecionado = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
