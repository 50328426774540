<template>
  <v-app-bar app color="primary" height="55" absolute flat>
    <v-sheet width="5%" class="d-none d-sm-block large-width-only"/>
    <template v-slot:img="{ props }">
      <v-img
          v-bind="props"
          gradient="to bottom right, rgba(19,84,95,.2), rgba(137,199,158, .9)"
      ></v-img>
    </template>
    <router-link to="/" style="text-decoration: none; color: inherit;">
      <div class="d-flex align-center">
        <v-img
            alt="Minerva Logo"
            class="shrink mr-5"
            contain
            src="@/assets/minerva_nova.png"
            transition="animate__animated animate__flipInX animate__slow"
            width="45"
            height="45"
        />
        <div class="align-center">
          <h2 class="logo d-none d-sm-flex">SGA UFRJ</h2>
          <h3 class="logo d-sm-none">SGA UFRJ</h3>
          <h4 id="logo-description" class="d-none d-sm-flex mt-n2 mb-1">
            Sistema de Gestão de Acesso
          </h4>
        </div>
      </div>
    </router-link>
    <v-spacer/>
    <LoginButton/>
    <Menu/>
    <v-sheet width="5%" class="d-none d-sm-block large-width-only"/>
  </v-app-bar>
</template>

<script>
import LoginButton from "@/components/login/LoginButton";
import Menu from "@/components/Menu"

export default {
  name: "Header",

  components: {
    LoginButton,
    Menu
  }
};
</script>

<style scoped>

</style>

