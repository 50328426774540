import Vue from "vue";
import App from "./App.vue";
import router from "./core/router";
import vuetify from "./core/vuetify";
import store from "./core/vuex";
import VCalendar from "./core/calendar";
import VueQuillEditor from "./core/vuequilleditor";
import "./core/vautonumeric";
import "./core/vuenprogress";
import "./core/animatecss";
import "./core/vueaxios";
import "./core/vmask";
import "./core/vueloadingoverlay";
import "./core/vueapexcharts";
import "./core/luxon";

Vue.config.productionTip = false;
Vue.prototype.$date = new Date();

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
  VCalendar,
  VueQuillEditor,
  beforeCreate() {
    window.addEventListener(
      "storage",
      async event => {
        if (event.key === "logout-event") {
          await store.dispatch("autenticacao/logout");
        }
      },
      false
    );
  }
}).$mount("#app");
