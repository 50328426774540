const state = {
    mensagem: false,
    tipo: 'info',
    exibir: false,
}

const mutations = {
    exibirAviso(state, { mensagem, tipo }) {
        state.mensagem = mensagem
        state.tipo = tipo
        state.exibir = true
    },

    esconder(state) {
        state.exibir = false
    }
}

const actions = {
    exibirAviso({ commit }, { mensagem, tipo }) {
        commit('exibirAviso', { mensagem, tipo });
    },

    esconderAviso({ commit }) {
        commit('esconder')
    }
}

export const avisos = {
    namespaced: true,
    state,
    actions,
    mutations
};