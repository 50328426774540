import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from 'vuetify/src/locale/pt.ts'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#89c79e",
        secondary: "#C4E3CE",
        accent: "#F3B664",
        error: "#FF5252",
        info: "#82A0D8",
        success: "#4CAF50",
        warning: "#FFB300",
        anchor: "#ff8c00",
        shade: "#5d29ec",
        pdf:"#ec5353"
      }
    }
  },
  lang: {
    locales: { pt },
    current: 'pt',
  }
});
