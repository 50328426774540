<template>
  <div>
    <v-btn
        key="btnLogin"
        v-if="usuarioLogado === null"
        text
        color="#3d4441"
        min-height="30"
        min-width="75"
        @click.prevent="exibirLogin"
    >
      Entrar
      <v-icon right>mdi-login</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState("autenticacao", ["usuarioLogado"])
  },

  methods: {
    ...mapActions("autenticacao", ["exibirLogin"])
  }
};
</script>
