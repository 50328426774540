import Vue from "vue";

export default {
  recuperarSenha(dados) {
    return Vue.axios.post("recuperarSenha/public/envioEmail", {
      cpf: dados.cpf
    });
  },

  confirmarRecuperarSenha(dados) {
    return Vue.axios.post("recuperarSenha/public/confirmarRecuperarSenha", dados);
  }
};
