<template>
  <v-footer app color="secondary" absolute padless min-height="65">
    <v-row align="center" justify="center" no-gutters >
      <v-col cols="2"/>
      <v-col class="text-center align-center" cols="8">
        <span class="text-center"> {{ new Date().getFullYear() }} — <strong>Equipe SIGA / UFRJ</strong></span>
      </v-col>
      <v-col cols="2" class="mb-n10 text-end">
        <span style="font-size: 8pt; color: #444343" class="mr-2" v-if="isAdministrador() && apiBuildNumber">
          b{{ apiBuildNumber }}.f{{ vueBuildNumber }}
        </span>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "Footer",
  data() {
    return {
      vueBuildNumber: process.env.VUE_APP_BUILD_NUMBER
    }
  },

  methods: {
    ...mapGetters("autenticacao", ["isAdministrador"])
  },

  computed: {
    ...mapState("autenticacao", ["apiBuildNumber"])
  }
};
</script>

<style scoped/>