import {DateTime} from "luxon";

export default {
    dataConverter(data){
        return DateTime.fromSQL(data).isValid === true ? DateTime.fromSQL(data).setLocale('pt-BR') : DateTime.fromJSDate(data).setLocale('pt-BR') ;
    },

    dataConverterFromIso(data){
      return DateTime.fromISO(data).setLocale('pt-BR');
    },

    dataValida(data) {
        return DateTime.fromSQL(data).isValid || DateTime.fromISO(data).isValid;
    },

    local(){
        return DateTime.local().setLocale('pt-BR') ;
    },

    localDateToDateTime(data){
        return DateTime.fromFormat(data, "dd-LL-yyyy");
    }
}