export const Perfil = {
    Administrador: "ADMINISTRADOR",
    Avaliador: "AVALIADOR",
    Candidato: "CANDIDATO",
}

export const Processos = {
    MudancaFacultativaCurso: 'MUF',
    TransferenciaFacultativaCurso: 'TEF',
    IsencaoConcurso: 'ICA'
}

export const ProcessosTIM = [
    Processos.MudancaFacultativaCurso,
    Processos.IsencaoConcurso,
    Processos.TransferenciaFacultativaCurso
]
