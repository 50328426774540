import Vue from "vue";

export default {
  login(credenciais) {
    return Vue.axios.post("autenticacao/public/login", {
      usuario: credenciais.usuario,
      senha: credenciais.senha
    });
  },

  refresh() {
    return Vue.axios.get("autenticacao/public/refresh");
  },

  logout() {
    return Vue.axios.get("autenticacao/public/logout");
  }
};
