<template>
  <v-snackbar
      :value="exibir"
      @input="esconderAviso"
      timeout="10000"
      top
      elevation="2"
      text
      outlined
      app
      :color="estilo.cor"
  >
    <div class="d-flex align-center">
      <v-icon left :color="estilo.cor">{{ estilo.icone }}</v-icon>
      <span class="font-weight-medium">{{ mensagem }}</span>
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="esconderAviso">
        <v-icon small :color="estilo.cor">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  computed: {
    ...mapState("avisos", {
      mensagem: state => state.mensagem,
      tipo: state => state.tipo,
      exibir: state => state.exibir
    }),

    estilo() {
      let estilo = {};
      switch (this.tipo) {
        case "info":
          estilo.cor = "info";
          estilo.icone = "mdi-information-outline";
          break;
        case "success":
          estilo.cor = "success";
          estilo.icone = "mdi-check";
          break;
        case "error":
          estilo.cor = "error";
          estilo.icone = "mdi-alert-octagon-outline";
          break;
        case "warning":
          estilo.cor = "warning";
          estilo.icone = "mdi-alert-outline";
          break;
        default:
          estilo.cor = "black";
          estilo.icone = "mdi-bell-ring-outline";
          break;
      }
      return estilo;
    }
  },

  methods: {
    ...mapActions("avisos", ["esconderAviso"])
  }
};
</script>

<style scoped>
.v-snackbar {
  z-index: 9999;
}
</style>
