import Vue from "vue";

export default {
    recuperarProcessosPublicados() {
        return Vue.axios.get("processo/public/publicados");
    },

    recuperarProcessoDivulgacao(oid) {
        return Vue.axios.get("processo/public/divulgacao", {
            params: {oid: oid}
        });
    },

    recuperarProcesso(oid) {
        return Vue.axios.get("processo", {params: {oid: oid}});
    },

    recuperarPodeSeInscreverNoProcesso(oid) {
        return Vue.axios.get("processo/podeSeInscrever", {
            params: {oid: oid}
        });
    },

    recuperarProcessosPossuiClassificacao() {
        return Vue.axios.get("processo/consulta/possuiClassificacao");
    },

    recuperarProcessosUsaNotaEnem() {
        return Vue.axios.get("processo/consulta/usaNotaEnem");
    },

    recuperarProcessoAbertoParaRecurso(oid) {
        return Vue.axios.get("processo/abertoParaRecurso", {
            params: {oid: oid}
        });
    },

    recuperarProcessoAbertoParaDocumentacaoPendente(oid) {
        return Vue.axios.get("processo/abertoParaDocumentacaoPendente", {
            params: {oid: oid}
        });
    },

    recuperarProcessoConsultaPorNome(nomeProcesso) {
        return Vue.axios.get("processo/consulta/nome", {
            params: {
                nomeProcesso: nomeProcesso
            }
        });
    },

    recuperarProcessosConsulta() {
        return Vue.axios.get("processo/consulta", {});
    },

    recuperarProcessosConsultaPorTipoProcessoCodigo(codigo) {
        return Vue.axios.get("processo/consulta/tipoProcesso", {
            params: {codigo: codigo}
        });
    },

    recuperarProcessosConsultaPorSituacaoProcessoCodigo(codigo) {
        return Vue.axios.get("processo/consulta/situacaoProcesso", {
            params: {codigo: codigo}
        });
    },

    criarProcesso(dados) {
        return Vue.axios.post("processo", {
            nome: dados.nome,
            ano: dados.ano,
            periodo: dados.periodo,
            tipoProcesso: dados.tipoProcesso,
            informacaoComplementarProcesso: dados.informacaoComplementarProcesso,
            instrucoesEnvioDocumentos: dados.instrucoesEnvioDocumentos,
            especificacoes: dados.especificacoes,
            prefixoNumeroEnem: dados.prefixoNumeroEnem,
            processoDivulgacao: dados.processoDivulgacao,
            anoIngressoUniversidadeOrigem: dados.anoIngressoUniversidadeOrigem,
        })
    },

    atualizarProcesso(dados) {
        return Vue.axios.put("processo/" + dados.oid, {
            oid: dados.oid,
            nome: dados.nome,
            ano: dados.ano,
            periodo: dados.periodo,
            serial: dados.serial,
            tipoProcesso: dados.tipoProcesso,
            informacaoComplementarProcesso: dados.informacaoComplementarProcesso,
            instrucoesEnvioDocumentos: dados.instrucoesEnvioDocumentos,
            especificacoes: dados.especificacoes,
            prefixoNumeroEnem: dados.prefixoNumeroEnem,
            processoDivulgacao: dados.processoDivulgacao,
            anoIngressoUniversidadeOrigem: dados.anoIngressoUniversidadeOrigem,
        })
    },

    recuperarProcessosMaterialAplicacao() {
        return Vue.axios.get('processo/materialAplicacao');
    },

    recuperarProcessosRelatorio() {
        return Vue.axios.get('processo/relatorio');
    },

    recuperarProcessosComEnvioDocumentos() {
        return Vue.axios.get('processo/possuiEnvioDocumentos')
    }

}


