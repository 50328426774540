import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import NProgress from "nprogress";
import router from "@/core/router";
import store from "@/core/vuex";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

instance.defaults.withCredentials = true;

instance.interceptors.request.use(
  config => {
    NProgress.start();

    let usuarioLogado = store.state.autenticacao.usuarioLogado;

    if (usuarioLogado && usuarioLogado.validade > Date.now()) {
      config.headers.common["Authorization"] =
        "Bearer " + usuarioLogado.accessToken;
    }

    return config;
  },
  error => {
    NProgress.done();
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    NProgress.done();
    if (response.status === 204) {
      response.data = null;
    }
    return response;
  },
  async error => {
    NProgress.done();
    let path = "";
    if (error.response) {
      switch (error.response.status) {
        case 401: {
          if (
            error.response.data.error === "refresh_token_not_found" ||
            error.response.data.error === "invalid_grant"
          ) {
            break;
          }
          path = "/naoAutorizado";
          break;
        }
        case 403: {
          await store.dispatch("avisos/exibirAviso", {
            mensagem: "Sessão expirada. Efetue o login novamente",
            tipo: "error"
          });
          await store.dispatch("autenticacao/exibirLogin");
          break;
        }
        case 404: {
          break;
        }
        case 409: {
          break;
        }
        default:
          path = "/erro";
      }
    } else {
      path = "/erro";
    }

    if (path) {
      router.push(path).catch(() => {});
    }

    return Promise.reject(error);
  }
);

Vue.use(VueAxios, instance)
